// @flow

import axios from 'axios';
import { Auth } from 'aws-amplify';

const serverURL = process.env.REACT_APP_API_ENDPOINT;

const getNewJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const tok = currentSession.getIdToken().getJwtToken();
  return tok;
};

const authenticatedAxios = (user, baseURL = serverURL) => {
  return getNewJWTToken().then((jwtToken: ?string) => {
    // Create instance
    // inject local headers, if running locally
    let localHeaders = {};
    if (process.env.REACT_APP_ENV === 'dev') {
      localHeaders['x-mdp-email'] = 'test@email.com';
    }

    const defaultOptions = {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
        ...localHeaders
      }
    };
    let instance = axios.create(defaultOptions);
    // Set the AUTH token for any request
    instance.defaults.headers.common['Authorization'] = `Bearer ${
      jwtToken ? jwtToken : ''
    }`;
    return instance;
  });
};

const publicAxios = (url = '') => {
  const baseUrl = url !== '' ? url : serverURL;
  // Create instance
  const defaultOptions = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return axios.create(defaultOptions);
};

type AxiosResponse = {
  data: Object
};

const testNoAuth = (): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return _publicAxios.get('/testnoauth');
};

const testAuth = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/testauth');
  });
};

const uploadFile = (payload: Object): Promise<string> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/ehr/upload/demographics', payload);
  });
};

const downloadTemplateFile = (fileKey): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/file-management/file/key/${fileKey}`);
  });
};

const registerNewUser = (payload): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return _publicAxios.post('/user-management/users', payload);
};

const registerNewUserByInvite = (
  organizationId: String,
  token: String,
  payload: Object
): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return _publicAxios.post(
    `/user-management/users?organizationId=${organizationId}&token=${token}`,
    payload
  );
};

const getAllPatientsByOrg = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/all?${searchString ? searchString : ''}`
    );
  });
};

const getPatientStats = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/stats?${searchString ? searchString : ''}`
    );
  });
};

const getAllEvents = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/events?${searchString ? searchString : ''}`);
  });
};

const exportEventsToCSV = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/ehr/events/export?${searchString ? searchString : ''}`
    );
  });
};

const getPatientById = (id: string): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/${id}`);
  });
};

const getApiDocumentation = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/ehr/api/documentation');
  });
};

const getPatientHccCompendium = (
  patientId: String,
  version: String
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `patient/${patientId}/hcc_compendium?${version ? `v=${version}` : ''}`
    );
  });
};

const requestApiKeys = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/ehr/api/keys', payload);
  });
};

const getLatestPatientEcda = (
  patientId,
  queryString
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/${patientId}/ecda?${queryString}`);
  });
};

const getQueriesByOrg = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/queries/queries?${searchString ? searchString : ''}`
    );
  });
};

const getQueriesByOrgStats = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/queries/queries/stats?${searchString ? searchString : ''}`
    );
  });
};

const exportCSV = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/queries/queries/report?${searchString ? searchString : ''}`
    );
  });
};

const exportPatientsToCSV = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/export?${searchString ? searchString : ''}`
    );
  });
};

const exportCDAAsPDF = (payload = {}, queryStr): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post(`/ehr/export?${queryStr}`, payload);
  });
};

const getExportCDAAsPDF = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/export/${requestId}`);
  });
};

const getBatchNames = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/organization/organization/batchNames');
  });
};

const getBatchRequests = (batchName): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/batchRequests/${batchName}`);
  });
};

const getRawInfsCompendiums = (queryId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/${queryId}/rawCompendiums`);
  });
};

const downloadRawInfsCompendium = (
  queryId,
  requestId
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/ehr/${queryId}/rawCompendiums?requestId=${requestId}`
    );
  });
};

const exportFailedQueries = (batchName): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/failed-queries/export/${batchName}`);
  });
};

const submitPatientQuery = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios(null, `${serverURL}`).then((authAxios) => {
    return authAxios.post('/patients/patient/query/client', payload);
  });
};

const getUploadURL = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/file-management/upload/url', payload);
  });
};

const getPatientSuggestions = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patients?searchString=${searchString}`);
  });
};

const getPatientData = (patientId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/${patientId}`);
  });
};

const getPatientFileByHash = (patientId, fileHash): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/${patientId}/document/${fileHash}`);
  });
};

const getPatientChartFiles = (
  patientId,
  searchString
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/${patientId}/document?${
        searchString ? searchString : ''
      }`
    );
  });
};

const searchPatientDocuments = (
  patientId,
  searchTerm,
  searchProvider
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/ehr/patient/${patientId}/search?q=${searchTerm}&searchProvider=${searchProvider}`
    );
  });
};

const indexPatientDocumentsForSearch = (patientId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/${patientId}/document/index`);
  });
};

const getPatientHCCs = (patientId, searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/${patientId}/hcc?${searchString ? searchString : ''}`
    );
  });
};

const exportPatientHCCsByPatient = (
  patientId,
  searchString
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/${patientId}/hcc/csv?${
        searchString ? searchString : ''
      }`
    );
  });
};

const exportPatientHCCsByOrg = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/hcc/export?${searchString ? searchString : ''}`);
  });
};

const getPatientHCCExport = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/hcc/export/${requestId}`);
  });
};

const getPatientExport = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/export/${requestId}`);
  });
};

const getEventsExport = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/events/export/${requestId}`);
  });
};

const getHccSourceDocument = (patientId, fileHash): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/patient/${patientId}/hcc/source/${fileHash}`);
  });
};

const switchOrganization = (organizationId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/user-management/organization/switch', {
      organizationId: organizationId
    });
  });
};

const getFileUploadUrl = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/file-management/upload/url', payload);
  });
};

const getFileUploads = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/file-management/uploads');
  });
};

const downloadUserUploadedFile = (fileId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/file-management/uploads?f=${fileId}`);
  });
};

const getQueryFileUpload = (queryId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/queries/queries/${queryId}/upload`);
  });
};

const setIcdCode = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.put('/icd/hcc', payload);
  });
};

const addInternalNote = (hccId, payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post(`/ehr/hcc/${hccId}/note`, payload);
  });
};

const resendInvite = (inviteId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/user-management/admin/user/invite/${inviteId}/resend`
    );
  });
};

const confirmPasswordReset = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/user-management/confirm-password-reset');
  });
};

const getUser = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/user-management/user');
  });
};

const clearUserForceLogout = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/user-management/clearuserforcelogout');
  });
};

const getPatientChartIndexStatus = (patientId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients/patient/${patientId}/chart-index-status`);
  });
};

const getIcdSourcesAndNotes = (
  patientId,
  icdCode,
  nlpSource
): Promise<AxiosResponse> => {
  console.log('getIcdSourcesAndNotes', patientId, icdCode, nlpSource);

  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patients/patient/${patientId}/icd/${icdCode}?nlpSource=${nlpSource}`
    );
  });
};

const deleteIcdNote = (noteId): Promise<AxiosResponse> => {
  console.log('deleteIcdNote', noteId);

  return authenticatedAxios().then((authAxios) => {
    return authAxios.post(`/patients/patient/icd/delete-note/${noteId}`);
  });
};

const getSourceHistory = (
  patientId,
  icdCode,
  factName,
  nlpSource
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    const factNameParam = factName ? `?factName=${factName}` : '';
    const nlpSourceParam = `nlpSource=${nlpSource}`;
    const separator = factName ? '&' : '?';

    return authAxios.get(
      `/patients/patient/${patientId}/icd/${icdCode}/source-history${factNameParam}${separator}${nlpSourceParam}`
    );
  });
};

const getChangelogCompendiumsByHccId = (hccId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/ehr/changelogCompendium/${hccId}`);
  });
};

export default {
  testNoAuth,
  testAuth,
  uploadFile,
  downloadTemplateFile,
  registerNewUser,
  registerNewUserByInvite,
  getAllPatientsByOrg,
  getPatientStats,
  getPatientById,
  getApiDocumentation,
  getPatientHccCompendium,
  requestApiKeys,
  getLatestPatientEcda,
  getQueriesByOrg,
  exportCDAAsPDF,
  getExportCDAAsPDF,
  getBatchNames,
  getBatchRequests,
  getRawInfsCompendiums,
  downloadRawInfsCompendium,
  exportFailedQueries,
  submitPatientQuery,
  getUploadURL,
  getPatientSuggestions,
  exportCSV,
  exportPatientsToCSV,
  getPatientData,
  searchPatientDocuments,
  getPatientFileByHash,
  getPatientHCCs,
  getHccSourceDocument,
  exportPatientHCCsByOrg,
  switchOrganization,
  getPatientHCCExport,
  getPatientChartFiles,
  indexPatientDocumentsForSearch,
  getFileUploadUrl,
  getFileUploads,
  downloadUserUploadedFile,
  getQueryFileUpload,
  setIcdCode,
  getAllEvents,
  resendInvite,
  exportEventsToCSV,
  confirmPasswordReset,
  getUser,
  clearUserForceLogout,
  getPatientChartIndexStatus,
  addInternalNote,
  getSourceHistory,
  getChangelogCompendiumsByHccId,
  exportPatientHCCsByPatient,
  getPatientExport,
  getIcdSourcesAndNotes,
  deleteIcdNote,
  getQueriesByOrgStats,
  getEventsExport
};
