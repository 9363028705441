// @flow

import React, { useState, useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Switch, FormControlLabel, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MDPBackend from 'services/MDPBackend';
import PageTemplate from 'layout/PageTemplate';
import { SearchWrapper } from './pages/FileBrowser/FileBrowser.Style';
import PatientSearch from './pages/FileBrowser/PatientSearch';
import { log } from 'utils/jsUtils';
import useInterval from 'hooks/useInterval';
import Sidebar from './components/Sidebar';
import { showToastMsg } from 'features/toast-message-slice';
import { setVectorSearchEnabled } from 'features/halOrganization-slice';

const Patient = (): React.Node => {
  const { patientId } = useParams();
  const { user } = useSelector((state) => state.user);
  const halOrganization = useSelector((state) => state.halOrganization);
  const dispatch = useDispatch();
  const isLoadingVectorResults = useSelector(
    (state) => state.halOrganization.isLoadingVectorResults
  );
  const [loadingPatientData, setLoadingPatientData] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [hasSuggestions, setHasSuggestions] = useState(false);

  const [loadingIndexDocuments, setLoadingIndexDocuments] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingIndexStatus, setLoadingIndexStatus] = useState(false);
  const [totalIndexingDocuments, setTotalIndexingDocuments] = useState(null);
  const [totalIndexedDocuments, setTotalIndexedDocuments] = useState(0);
  const [totalUnindexedDocuments, setTotalUnindexedDocuments] = useState(0);
  const [totalUnsupportedDocuments, setTotalUnsupportedDocuments] = useState(0);
  const [totalChartDocuments, setTotalChartDocuments] = useState(0);
  const [isVectorSearch, setIsVectorSearch] = useState(false);

  const indexPatientDocumentsForSearch = async (patientId) => {
    console.log('[indexPatientDocumentsForSearch] patientId: ', patientId);
    setLoadingIndexDocuments(true);

    try {
      const response = await MDPBackend.indexPatientDocumentsForSearch(
        patientId
      );

      const parsedResponse = JSON.parse(response.data.body);
      console.log(
        '[indexPatientDocumentsForSearch] parsedResponse: ',
        parsedResponse
      );

      dispatch(
        showToastMsg({
          open: true,
          message:
            // eslint-disable-next-line max-len
            'Indexing pages started. This can take up to 5 minutes to complete. Please refresh the page to view the latest results.',
          level: 'success',
          duration: 5000
        })
      );

      await getPatientChartIndexStatus(patientId);
    } catch (error) {
      console.log('[indexPatientDocumentsForSearch] error: ', error);
      dispatch(
        showToastMsg({
          open: true,
          message: error.message,
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoadingIndexDocuments(false);
    }
  };

  const getPatientChartIndexStatus = async (patientId) => {
    log('[getPatientChartIndexStatus] patientId: ', patientId);
    setLoadingIndexStatus(true);

    try {
      const response = await MDPBackend.getPatientChartIndexStatus(patientId);
      const parsedResponse = JSON.parse(response.data.body);
      log('[getPatientChartIndexStatus] parsedResponse: ', parsedResponse);

      const indexedChartDocuments = parsedResponse.indexedChartDocuments;
      const unindexedChartDocuments = parsedResponse.unindexedChartDocuments;
      const currentIndexingChartDocuments =
        parsedResponse.currentIndexingChartDocuments;
      const totalChartDocuments = parsedResponse.totalChartDocuments;
      const unsupportedChartDocuments =
        parsedResponse.unsupportedChartDocuments;
      setTotalChartDocuments(totalChartDocuments);
      setTotalIndexedDocuments(indexedChartDocuments);
      setTotalUnsupportedDocuments(unsupportedChartDocuments);
      setTotalUnindexedDocuments(
        unindexedChartDocuments - unsupportedChartDocuments
      );
      setTotalIndexingDocuments(currentIndexingChartDocuments);
    } catch (error) {
      log('[getPatientChartIndexStatus] error: ', error);
    } finally {
      setLoadingIndexStatus(false);
    }
  };

  const getPatientData = async (patientId) => {
    console.log('[getPatientData] patientId: ', patientId);
    setLoadingPatientData(true);

    try {
      const response = await MDPBackend.getPatientData(patientId);
      console.log('[getPatientData] response: ', response);

      const parsedData = JSON.parse(response.data.body).data;
      console.log('[getPatientData] parsedData: ', parsedData);

      if (!parsedData && response.data.statusCode !== 200) {
        const responseMsg = response.data.body;

        dispatch(
          showToastMsg({
            open: true,
            message: responseMsg ? responseMsg : 'Network Error',
            level: 'error',
            duration: 5000
          })
        );
      }

      setPatientData(parsedData);
      setLoadingPatientData(false);
      console.log('');
    } catch (error) {
      console.log('[getPatientData] error: ', error);
      setLoadingPatientData(false);
    }
  };

  useEffect(() => {
    getPatientData(patientId);
    getPatientChartIndexStatus(patientId);
  }, []);

  useInterval(() => {
    console.log('[useInterval]...');

    if (
      typeof totalIndexingDocuments === 'number' &&
      totalIndexingDocuments > 0
    ) {
      getPatientChartIndexStatus(patientId);
    }
  }, 5000);

  const handleSwitchChange = () => {
    setIsVectorSearch((prevIsVectorSearch) => {
      if (prevIsVectorSearch === false) {
        dispatch(setVectorSearchEnabled(true));
        return true;
      }

      if (prevIsVectorSearch === true) {
        dispatch(setVectorSearchEnabled(false));
        return false;
      }
    });
  };

  return (
    <PageTemplate backgroundColor="#FBFBFB">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patients</title>
      </Helmet>
      {user && user?.role !== 'payer' && (
        <Sidebar
          patientId={patientId}
          hasCDA={patientData && patientData.cda}
        />
      )}
      <SearchWrapper>
        <PatientSearch
          patientId={patientId}
          setHasSuggestions={setHasSuggestions}
          patientData={patientData}
          loadingPatientData={loadingPatientData}
          totalIndexedDocuments={totalIndexedDocuments}
        />{' '}
        {isVectorSearch ? null : (
          <Container className="!mx-0 !px-0" maxWidth="xl">
            <Typography style={{ fontSize: '0.9rem', marginTop: 10 }}>
              {totalIndexedDocuments} of {totalChartDocuments} documents
              indexed.{' '}
              {totalUnsupportedDocuments
                ? `${totalUnsupportedDocuments} documents not supported for
              search capability. `
                : ''}
              {totalIndexingDocuments
                ? `Indexing ${totalIndexingDocuments} documents...`
                : ''}
              {totalUnindexedDocuments > 0 && !totalIndexingDocuments ? (
                <a
                  onClick={() => indexPatientDocumentsForSearch(patientId)}
                  style={{
                    textDecoration: 'underline',
                    pointerEvents: loadingIndexDocuments ? 'none' : 'auto'
                  }}
                >
                  {loadingIndexDocuments
                    ? 'Indexing, please wait...'
                    : // eslint-disable-next-line max-len
                      `Click here to index the ${totalUnindexedDocuments} documents for search capability`}
                </a>
              ) : null}
            </Typography>
          </Container>
        )}
        {halOrganization.isUserHalOrganization && !isLoadingVectorResults ? (
          <Container className="!mx-0 !px-0" maxWidth="xl">
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={isVectorSearch}
                    onChange={handleSwitchChange}
                    name={`switch-search`}
                    color="primary"
                    sx={{
                      marginLeft: '5px',
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'green'
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: 'green'
                        },
                      '& .MuiSwitch-switchBase': {
                        color: 'primary.main'
                      },
                      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                        backgroundColor: 'primary.main'
                      }
                    }}
                  />
                }
                label={isVectorSearch ? 'Semantic Search' : 'Simple Search'}
              />
            </Box>
          </Container>
        ) : null}
      </SearchWrapper>

      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          background: 'grey',
          opacity: 0.8,
          display: hasSuggestions ? 'block' : 'none',
          zIndex: 9
        }}
      />
      <Outlet context={{ patientId, loadingPatientData, hasSuggestions }} />
    </PageTemplate>
  );
};

export default Patient;
